import Cookies from "js-cookie";
const TokenKey: string = "Token";
const isLoggedIn: string = "isLoggedIn";

export function getToken(): string | undefined {
  return Cookies.get(TokenKey);
}

export function setToken(token: string): string | undefined {
  Cookies.set(isLoggedIn, "true");
  return Cookies.set(TokenKey, token);
}
export function clearAllCookie() {
  Cookies.set(isLoggedIn, "false");
  Cookies.remove(TokenKey);
}
