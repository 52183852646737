import { useState, useEffect } from "react";
// import { Dialog } from "@headlessui/react";
// import { Bars3Icon, XMarkIcon, BellIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { getToken, clearAllCookie } from "../../utils/Token";
// import { v4 as uuidv4 } from "uuid";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import Image from "next/image";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { UserInfo } from "../../store/main/actions";
import { usePlausible } from "next-plausible";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  // { name: "SOP Writer", href: `/sop/new/${uuidv4()}/step-1` },
  { name: "Pricing", href: "/pricing" },
  { name: "SOP Templates", href: "/sop-templates" },
  { name: "Contact Us", href: "/contact-us" },
  { name: "Referral Dashboard", href: "/referralDashboard" },
  { name: "Blog", href: "https://tootler.ai/blog" },
  {
    name: "Logout",
    href: "/accounts/login",
    onclick: clearAllCookie,
  },
];
interface NavbarProps {
  name: string;
  href: string;
  links: Array<{ name: string; href: string }>;
}

const Navbar = (data: NavbarProps) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setToken(getToken());
  }, []);
  const plausible = usePlausible();

  const handleClick = (buttonId: any) => {
    try {
      plausible(buttonId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Disclosure as="nav" className={`bg-white`}>
      {({ open }) => (
        <>
          <div className="px-0 py-0">
            <div className="relative flex h-5 items-center justify-between">
              <div className="absolute inset-y-0  right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <div>
                    <Menu.Button
                      as="button"
                      // className="flex rounded-full bg-tootler-primary-50 text-sm hover:scale-105 transition ring-offset-2 focus-visible:ring-2 ring-tootler-primary-400"
                      className={classNames(
                        "flex justify-center items-center rounded-full text-sm group"
                      )}
                    >
                      {({ open }) => (
                        <>
                          <span className="sr-only">Open user menu</span>
                          <div className="font-semibold">{data.name}</div>
                          <ChevronDownIcon className="h-5 w-5 ml-2 group-hover:translate-y-0.5 transition" />
                        </>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {data?.links.map((item) => (
                        <Menu.Item key="">
                          {({ active }) => (
                            <Link
                              href={item.href}
                              key=""
                              onClick={() => handleClick(`nav-${item.name}`)}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-tootler-primary-900 "
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
