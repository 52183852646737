import { useState, useEffect, Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { getToken, clearAllCookie } from "../../utils/Token";
import { v4 as uuidv4 } from "uuid";
import Image from "next/image";
import { usePlausible } from "next-plausible";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { UserInfo } from "../../store/main/actions";
import Dropdown from "./dropdown";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  // { name: "SOP Writer", href: `/sop/new/${uuidv4()}/step-1` },
  { name: "Pricing", href: "/pricing", links: [] },
  {
    name: "Solution",
    href: "/",
    links: [
      { name: "SOP Generator", href: `/statement-of-purpose-generator` },
      { name: "SOP Visa", href: "/statement-of-purpose-visa" },
      // { name: "Immigration Chatbot", href: "/immigration" },
      //  { name: "SOP Reviewer", href: `/sop-reviewer` },
      { name: "Rejection Letter", href: "/rejection-letter" },
    ],
  },
  { name: "Immigration Chatbot", href: "/immigration", links: [] },
  { name: "SOP Templates", href: "/sop-templates", links: [] },
  { name: "Contact Us", href: "/contact-us", links: [] },
  { name: "Blog", href: "https://tootler.ai/blog", links: [] }, //deployed separately
  { name: "Referral Dashboard", href: "/referralDashboard", links: [] },
];

interface NavbarProps {
  userInfo?: UserInfo;
}

const Navbar = ({ userInfo }: NavbarProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    setToken(getToken());
  }, []);
  const plausible = usePlausible();

  const handleClick = (buttonId: any) => {
    try {
      plausible(buttonId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="mx-auto pt-10 px-4 md:px-8 xl:px-0 max-w-7xl ">
      <nav className="flex items-center justify-between" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link href="/" className="">
            <Image
              src="/logo/newLogo.svg"
              height={50}
              width={40}
              alt="Logo"
              className="w-40"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              className="h-7 w-7 items-center text-tootler-primary-900 font-medium"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-11 ml-10">
          {navigation.map((item) =>
            item.links.length > 0 ? (
              <Dropdown key={item.name} {...item} />
            ) : (
              <Link
                onClick={() => handleClick(`nav-${item.name}`)}
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-tootler-primary-900"
              >
                {item.name}
              </Link>
            )
          )}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {token === undefined ? (
            <>
              <Link
                onClick={() => handleClick(`nav-login`)}
                href="/accounts/login"
                className="text-sm font-semibold leading-6 text-tootler-primary-900"
              >
                Log in <span aria-hidden="true">&rarr;</span>
              </Link>
            </>
          ) : (
            <div className="space-x-8 mb-1 text-gray-900">
              <DropdownUserMenu />
            </div>
          )}
        </div>
      </nav>
      <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
          <div className="flex items-center justify-between">
            <Link href="/" className="">
              <Image
                src="/logo/newLogo.svg"
                height={50}
                width={40}
                alt="Logo"
                className="w-52 h-24 "
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon
                className="h-7 w-7 font-medium text-tootler-primary-900"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) =>
                  item.links.length > 0 ? (
                    item.links.map((link) => (
                      <Link
                        onClick={() => handleClick(`nav-${link.name}`)}
                        key={link.name}
                        href={link.href}
                        className="block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-tootler-primary-900 hover:bg-gray-400/10"
                      >
                        {link.name}
                      </Link>
                    ))
                  ) : (
                    <Link
                      onClick={() => handleClick(`nav-${item.name}`)}
                      key={item.name}
                      href={item.href}
                      className="block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-tootler-primary-900 hover:bg-gray-400/10"
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
              <div className="py-6">
                {token === undefined ? (
                  <>
                    <Link
                      onClick={() => handleClick(`nav-login`)}
                      href="/accounts/login"
                      className="text-sm font-semibold leading-6 text-tootler-primary-900"
                    >
                      Log in <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </>
                ) : (
                  <div className="space-x-8 mb-1 text-tootler-primary-900">
                    <Link
                      onClick={() => {
                        handleClick("nav-dashboard");
                      }}
                      href="/sop/dashboard"
                      className="inline rounded-lg bg-tootler-primary-900 py-2.5 px-5 w-full text-center text-sm font-medium text-white hover:bg-primary-900/90 hover:scale-105 transition"
                    >
                      Dashboard
                    </Link>
                    <Link
                      href="/"
                      onClick={() => {
                        clearAllCookie();
                        handleClick("nav-log-out");
                      }}
                      className="text-sm font-semibold leading-6 text-tootler-primary-900 py-2"
                    >
                      Logout
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

export default Navbar;

const dropdownMenuLinks = [
  {
    name: "Logout",
    href: "/accounts/login",
    onclick: (handleClick: any) => {
      clearAllCookie();
      handleClick("nav-log-out");
    },
  },
  {
    name: "Dashboard",
    href: "/sop/dashboard",
    onclick: (handleClick: any) => {
      handleClick("nav-dashboard");
    },
  },
];

const DropdownUserMenu = ({ userInfo }: { userInfo?: UserInfo }) => {
  const plausible = usePlausible();

  const handleClick = (buttonId: any) => {
    try {
      plausible(buttonId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="absolute inset-y-0  right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button
              as="button"
              // className="flex rounded-full bg-tootler-primary-50 text-sm hover:scale-105 transition ring-offset-2 focus-visible:ring-2 ring-tootler-primary-400"
              className={classNames(
                "flex justify-center items-center rounded-full text-sm group"
              )}
            >
              {({ open }) => (
                <>
                  {/* <span className="sr-only">Open user menu</span>
                  <img
                    // className="
                    // h-8 w-8  rounded-full group-hover:scale-105 transition ring-offset-2 group-focus-visible:ring-2 ring-tootler-primary-700"

                    className={classNames(
                      "h-8 w-8 rounded-full transition ring-offset-2 ring-tootler-primary-700",
                      open ? "ring-2" : ""
                    )}
                    src={userInfo?.photo_url ?? "/logo/default-user.png"}
                    alt=""
                  />
                  <ChevronDownIcon className="h-5 w-5 ml-2 group-hover:translate-y-0.5 transition" /> */}
                  {dropdownMenuLinks.map((item) => (
                    <Menu.Item key="">
                      {({ active }) => (
                        <Link
                          href={item.href}
                          key={item.name}
                          onClick={() => item.onclick(handleClick)}
                          className={classNames(
                            active ? "" : "",
                            "block px-4 py-2 text-sm font-semibold text-tootler-primary-900 "
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </>
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {/* <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {dropdownMenuLinks.map((item) => (
                <Menu.Item key="">
                  {({ active }) => (
                    <Link
                      href={item.href}
                      key={item.name}
                      onClick={() => item.onclick(handleClick)}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-tootler-primary-900 "
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items> */}
          </Transition>
        </Menu>
      </div>
    </>
  );
};
